import React, { useState, useEffect, createRef } from "react";

const Card = ({ project, openModal }) => {
  const { image, title, photos } = project;

  return (
    <div
      style={{
        width: "33.3%",
        overflow: "hidden",
        aspectRatio: "1 / 1",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 4,
        border: "4px solid #C3CFD9",
        flexDirection: "column",
      }}
      onClick={() => openModal(photos)}
    >
      <div
        style={{
          height: "80%",
          width: "100%",
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div
        style={{
          padding: "4%",
          fontSize: "2vw",
          fontFamily: "Roboto",
          lineHeight: 1,
          fontWeight: "600",
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default Card;
